<template>
  <div class="layoutContainer">
    <div class="secondFloat">
      <el-form :inline="true" ref="formInline" :model="formInline" class="demo-form-inline">
        <el-form-item label="样式名称" prop="styleName">
          <el-input v-model="formInline.styleName" placeholder="请输入样式名称"></el-input>
        </el-form-item>
        <el-form-item label="有效状态" prop="validatatus">
          <el-select v-model="formInline.validstatus">
            <el-option label="启用" value="1"></el-option>
            <el-option label="禁用" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" icon="el-icon-search">查询</el-button>
          <el-button plain @click="reset('formInline')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableDetail">
      <el-button type="primary" icon="el-icon-plus" @click="add()">新增</el-button>
      <el-table style="width:100%" border :data="resultList" :header-cell-style="{'text-align':'center'}" :cell-style="{'text-align':'center'}">
        <el-table-column label="名称" prop="name"></el-table-column>
        <el-table-column label="有效状态" prop="useFlag">
          <template slot-scope="{row}">
            <span v-if="row.useFlag === 1">启用</span>
            <span v-if="row.useFlag === 0 " style="color:red">禁用</span>
          </template>
        </el-table-column>
        <el-table-column label="备注" prop="remark"></el-table-column>
        <el-table-column label="更新时间" prop="updatedTime" :formatter="dateFormat"></el-table-column>
        <el-table-column label="更新人" prop="updatedUserName"></el-table-column>
        <el-table-column label="操作" fixed="right" width="200px">
          <template slot-scope="{row}">
            <el-button type="primary" size="mini"  @click="edit(row)">编辑</el-button>
            <el-button type="primary" size="mini" style="background:#FF5722" @click="del(row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination v-show="total>0" :total="total" :page.sync="currentPage" :limit.sync="pagesize" @pagination="dataList"/>
      <el-dialog :title="textMap[dialogStatus]"  :visible.sync="dialogFormVisible" :close-on-click-modal="false">
      <el-form  ref='dataForm' label-position="center" :rules="styleRules" :model="styleInfo">
          <el-form-item label="名称" prop="name" :label-width="formLabelWidth">
            <el-input v-model="styleInfo.name" placeholder="请输入样式名称"></el-input>
          </el-form-item>
    
         <el-form-item label="样式:" prop="zipPath" :label-width="formLabelWidth">
              <el-upload
                class="upload-demo"
                style="max-width: 379px;"
                :action="uploadUrl"
                :headers="importHeader"
                :on-success="handleOnSuccess"
                :on-remove="handleOnRemove"
                :before-remove="beforeOnRemove"
                :before-upload="beforeAvatarUpload"
                :on-exceed="handleOnExceed"
                 multiple
                :limit="1"
                :file-list="fileList"
                 accept=".ZIP, .zip"
              >
                <el-button size="min" icon="el-icon-upload" type="primary">点击上传</el-button>
              </el-upload>
          </el-form-item>
           <el-form-item label="有效状态:" prop="useFlag" :label-width="formLabelWidth">
                <el-radio v-model="styleInfo.useFlag" :label="1">启用</el-radio>
                <el-radio v-model="styleInfo.useFlag" :label="0">禁用</el-radio>
          </el-form-item>
          <el-form-item label="备注:" prop="remark" :label-width="formLabelWidth">
           <el-input type="textarea"  rows="2" v-model="styleInfo.remark" ></el-input>
         </el-form-item>
        <el-form-item :label-width="formLabelWidth" style=" text-align:center;">
          <el-button type="primary" @click="onSubmit" >
            立即提交
          </el-button>
          <el-button @click="dialogFormVisible = false" >
            取消
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    </div>
  </div>
</template>
<script>
import { sysServerUrl,cmsServerUrl, obj } from '@/assets/js/common.js'
import Pagination from '@/components/Pagination'
import { styleData, styleAdd, styleEdit, styleDel } from '@/api/api.js'
import axios from 'axios'
export default {
  name: 'layoutContainer',
  components: { Pagination },
  data () {
    return {
      name: '更多',
      formInline: {
        styleName: '',
        validstatus: ''
      },
      textMap: {
        add: '新增样式',
        edit: '编辑样式'
      },
      styleInfo: {
        id: 0,
        name: '',
        zipPath: '',
        useFlag: 0,
        remark: ''
      },
      uploadUrl: '',
      fileList: [],
      styleRules: {
        name: [{ required: true, message: '名称不能为空', trigger: 'blur' }],
        zipPath: [{ required: true, message: '样式不能为空', trigger: 'blur' }],
        useFlag: [{ required: true, message: '是否有效不能为空', trigger: 'blur' }]
      },
      dialogFormVisible: false,
      dialogStatus: '',
      formLabelWidth: '100px',
      resultList: [],
      pagesize: 10,
      currentPage: 1,
      total: 0
    }
  },
  computed: {
    importHeader: function () {
      return { Authorization: sessionStorage.token }
    }
  },
  methods: {
    onSubmit () {
      if(this.styleInfo.zipPath==''){
        this.$message.error('样式不能为空，请上传样式文件');
        return  false
      }
      var params = new URLSearchParams()
      params.append('id', this.styleInfo.id)
      params.append('name', this.styleInfo.name)
      params.append('zipPath',this.styleInfo.zipPath)
      params.append('useFlag', this.styleInfo.useFlag)
      params.append('remark', this.styleInfo.remark)

      if( this.dialogStatus === 'add'){
        //新增样式
        styleAdd(params).then(res => {
           if(res.data.code==100){
            this.$message.success('添加样式成功')
            this.dialogFormVisible=false
            this.dataList()
          }else{
            this.$message.success(res.data.msg)
          }
        }).catch(function(error){
          this.$message.error('添加样式失败')
        })
      }else if( this.dialogStatus === 'edit'){
        //更新样式
        styleEdit(params).then(res => {
          if(res.data.code==100){
            this.$message.success('修改样式成功')
            this.dialogFormVisible=false
            this.dataList()
          }
        }).catch(function(error){
          this.$message.error('修改样式失败')
        })
      }
    },
    handleOnSuccess (res, obj) {
      this.styleInfo.zipPath=res.data.fileUrl
      this.fileList=[]
      var file = {name: res.data.fileName, url: res.data.fileUrl}
      this.fileList.push(file)
    },
    beforeOnRemove(file, fileList) {
      return this.$confirm(`确定移除文件 ${ file.name }？`);
    },
    handleOnRemove(file,fileList){
      console.log(file)
      console.log(fileList)
    },
    beforeAvatarUpload (file) {
      const extension = file.name.split('.')[1] === 'zip'
      const isLt2M = file.size / 1024 / 1024 < 10
      if (!extension ) {
        this.$message.warning('上传样式模板只能是 zip 格式!')
      }
      if (!isLt2M) {
        this.$message.warning('上传样式模板大小不能超过 10 MB!')
      }
    },
    handleOnExceed (files, fileList) {
      this.$message.warning(`当前限制选择1个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
  
    add () {
      this.dialogStatus = 'add'
      this.styleInfo.id=0
      this.styleInfo.name=''

      this.styleInfo.zipPath=''
      this.styleInfo.useFlag=1
      this.styleInfo.remark=''
      this.fileList=[]

      this.dialogFormVisible = true

    },
    edit (row) {
      this.dialogStatus = 'edit'
      this.styleInfo.id=row.id
      this.styleInfo.name=row.name
      this.styleInfo.zipPath=row.zipPath
      this.styleInfo.useFlag=row.useFlag
      this.styleInfo.remark=row.remark
      this.fileList=[]
      let fileName=row.zipPath
      let index =fileName.lastIndexOf('\\')
      if(index!=-1){
          fileName=fileName.substring(index+1)
      }
      var file = {name: fileName, url: row.zipPath}
      this.fileList.push(file)
      this.dialogFormVisible = true
    },
    del (row) {
      this.$confirm('确定删除样式【'+ row.name + '】?', '删除手册样式', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        styleDel(row.id).then(res => {
          if (res.data.code === 100) {
            this.$message({
              type: 'success',
              message: '删除成功'
            })
            this.dataList()
          }else{
            this.$message.error(res.data.msg)
          }
        })
      }).catch((error) => {
          this.$message.error('删除样式失败')
      })
    },
    // 获取数据
    dataList () {
      var params = {
        page: this.currentPage,
        limit: this.pagesize
      }
      styleData(params).then(res => {
        this.total = res.data.total
        this.resultList = res.data.data
      })
    },
    reset (formInline) {
      if (this.$refs[formInline].resetFields() !== undefined) {
        this.$refs[formInline].resetFields()
      }
      this.dataList()
    },
    dateFormat (row, column, cellValue, index) {
      if (cellValue !== null) {
        const date = new Date(parseInt(cellValue))
        const Y = date.getFullYear() + '-'
        const M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-'
        const D = date.getDate() < 10 ? '0' + date.getDate() + '' : date.getDate() + ''
        return Y + M + D
      }
    }
  },
  mounted () {
   this.uploadUrl =sysServerUrl + 'sys/upload/attach?flag=temp'
    this.dataList()
  }
}
</script>
<style>
  .el-button-group .el-button--primary:first-child{
    display: none;
  }
  .el-button-group .el-button--primary:last-child{
    border-radius: 5px;
  }
  .el-dropdown .el-dropdown__caret-button::before {
    content: '更多';
    position: relative;
    display:inline-block;
    width:auto;
    top: 0px;
    bottom: 0px;
    left: 0;
    background: transparent;
  }

  .el-upload-list__item {
   transition: none !important;
  }
</style>
